import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
// import Link from 'gatsby'

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../components/styles/header-slider.css';

import Layout from '../components/Layout'
import Partnership from '../components/Partnership'
// import BlogRoll from '../components/BlogRoll'
import Services from '../components/Services'
import Form from '../components/Form'
import {ShowUSP, ShowTable} from '../components/Hider'
import Tick from '../img/tick.png'

export const IndexPageTemplate = ({
    header,
    marshall,
    analysis,
    membership,
    partnership,
    services,
    contact
}) => (
    <div id="home">
        <Slider autoplay="3000" infinite={true}>

            {header.map((index) => <div key={index}>
                <div
                    className="full-width-image margin-top-0"
                    style={{
                    backgroundImage: `url(${ !!index.image.childImageSharp
                        ? index.image.childImageSharp.fluid.src
                        : index.image})`,
                    backgroundAttachment: `fixed`,
                    minHeight: '100vh',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPositionY: 'inherit',
                    width: '80%',
                    marginLeft: 'auto',
                    marginRight: '0'
                }}>
                    <div className="container header-container" style={{
                        marginLeft: '-15%'
                    }}>
                        <h1
                            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet"
                            style={{
                            color: 'black',
                            lineHeight: '1',
                            textAlign: 'left'
                        }}>
                            {index.title}
                        </h1>
                        <h1
                            className="has-text-weight-normal is-size-3-mobile is-size-2-tablet"
                            style={{
                            color: 'black',
                            lineHeight: '1',
                            textAlign: 'left'
                        }}>
                            {index.subtitle}
                        </h1>
                        <h3
                            className="has-text-weight-normal is-size-5-mobile is-size-5-tablet"
                            style={{
                            color: 'black',
                            lineHeight: '1.5',
                            textAlign: 'left',
                            maxWidth: '55%',
                            marginLeft: '0',
                            marginRight: 'auto'
                        }}>
                            {index.text}
                        </h3>
                    </div>
                </div>

            </div>)}
        </Slider>

        <div className="content">

            <section className="blue-bg" id="about">
                <div className="general-container">
                    <div className="about-block">
                        <div className="about-text">
                            <h2 className="is-capitalized has-text-left about-title">{marshall.heading1}</h2>
                            <p className="is-size-5 has-text-weight-normal has-text-left">{marshall.description}</p>
                        </div>
                        <div className="about-image">
                            <img
                                alt="Marshall Gittler"
                                src={!!marshall.image.childImageSharp
                                ? marshall.image.childImageSharp.fluid.src
                                : marshall.image}
                                style={{
                                width: 350
                            }}/>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about">
                <div className="general-container">
                    <div className="about-block">
                        <div className="about-image">
                            <h1 className="is-capitalized has-text-left">{analysis.heading1}</h1>
                            <h3 className="is-capitalized has-text-left">{analysis.heading2}</h3>
                        </div>
                        <div className="about-text">

                            <p className="is-size-5 has-text-weight-normal has-text-left">{analysis.description}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="blue-bg" id="service">
                <div className="feat bg-gray pt-5 pb-5">
                    <div className="container">
                        <div className="section-head col-sm-12">
                            <h1 className="is-size-1">{services.title}</h1>
                            <h1 className="font-weight-normal">{services.subtitle}</h1>
                        </div>
                        <Services gridItems={services}/>
                    </div>
                </div>
            </section>

            <section className="" id="membership">
                <div className="general-container">
                    <h1>{membership.title}</h1>
                    <button className="switcher selected" id="table-btn" onClick={ShowTable}>{membership.btn1}</button>
                    <button className="switcher" id="usp-btn" onClick={ShowUSP}>{membership.btn2}</button>
                    <div id="member-table">

                        <table className="tg">
                            <tbody>
                                <tr>
                                    <th className="tg-0lax table-titles">{membership.ttitle}</th>
                                </tr >
                                {membership
                                    .table
                                    .map(item => (
                                        <tr>
                                            <th className="tg-0lax">{item.title}</th>
                                        </tr >
                                    ))}
                            </tbody>
                        </table>

                        <table className="tg">
                            <tbody>
                                <tr>
                                    <th className="tg-0lax">
                                        <span className="table-titles">{membership.cost}</span>(or equivalent in USD or GBP)</th>
                                </tr >
                                {membership
                                    .table
                                    .map(item => (
                                        <tr>
                                            <th
                                                className="tg-0lax"
                                                style={{
                                                display: 'none'
                                            }}>{item.title}</th>
                                            < th className="tg-0lax"><img alt="tick" className="tick-icon" src={Tick}/></th>
                                        </tr >
                                    ))}
                            </tbody>
                        </table>
                        
                    </div>
                    
                    <div id="member-usp" className="hidden">

                        <Services gridItems={membership}/>
                    </div>
                    <div className="mt-2" style={{textAlign: 'center'}}>
                            <button
                                style={{background: '#957C35',
                                    borderRadius: '32px',
                                    border: 'none',
                                    padding: '10px 44px',
                                    color: 'white'}}>SUBSCRIBE</button>
                                    <p><br/><br/>
                                    *Pre-book for the next 6 months, earn a 15% discount and save EUR 135! <br/>
                                    *Pre-book for a year, earn a 30% discount and save EUR 540! <br/><br/>
                                    **Webinars will be run by Marshall Gittler and Barbara Rockefeller.<br/>
                                    ***Seminars will be run in different regions. Members will be notified on locations and dates.</p>
                        </div>
                </div>
            </section>

            <section id="service">
                <div className="feat bg-gray pt-5 pb-5">
                    <div className="container">
                        <Partnership gridItems={partnership}/>
                        <div style={{textAlign: 'center'}}>
                            <button
                                style={{background: '#957C35',
                                    borderRadius: '32px',
                                    border: 'none',
                                    padding: '10px 44px',
                                    color: 'white'}}>CONTACT</button>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="general-container" id="blog">
                <div className="column is-12">
                    <h3 className="has-text-weight-bold has-text-centered is-size-2">
                        BLOG
                    </h3>
                    <BlogRoll/>
                    <div className="column is-12 has-text-centered">
                        <Link className="blog-btn" to="/blog">
                            Read more
                        </Link>
                    </div>
                </div>
            </section> */}
            <section className=" contact-section" id="contact">
                <div className="general-container">
                    <h1 className="text-center is-uppercase">Contact Us</h1>
                    <div className="contact-details">
                        <div>
                            <p>{contact.phone}</p>
                            <p>{contact.email}</p>
                            <p>{contact.address}</p>
                        </div>
                        <div>
                            <Form/>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
)

IndexPageTemplate.propTypes = {
    header: PropTypes.array,
    marshall: PropTypes.shape({icons: PropTypes.array}),
    analysis: PropTypes.object,
    membership: PropTypes.shape({table: PropTypes.array, usp: PropTypes.array}),
    partnership: PropTypes.shape({icons: PropTypes.array}),
    services: PropTypes.shape({usp: PropTypes.array}),
    contact: PropTypes.object
}

const IndexPage = ({data}) => {
    const {frontmatter} = data.markdownRemark

    return (
        <Layout>
            <IndexPageTemplate
                header={frontmatter.header}
                marshall={frontmatter.marshall}
                analysis={frontmatter.analysis}
                membership={frontmatter.membership}
                partnership={frontmatter.partnership}
                services={frontmatter.services}
                contact={frontmatter.contact}/>
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({frontmatter: PropTypes.object})
    })
}

export default IndexPage

export const pageQuery = graphql `
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        header {
          title
          subtitle
          text
          image {
            childImageSharp {
              fluid(maxWidth: 20048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        marshall {
          heading1
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        analysis {
          heading1
          heading2
          description
        }
        services {
          title
          subtitle
          usp {
            title
            description
            icon {
              childImageSharp {
                fluid(maxWidth: 240, quality: 32) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        membership {
          title
          btn1
          ttitle
          cost
          table {
            title
          }
          btn2
          usp {
            icon {
              childImageSharp {
                fluid(quality: 16) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            description
          }
        }
        partnership {
          title
          subtitle
          text
          icons {
            image {
              childImageSharp {
                fluid(quality: 16) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
          }
        }
        contact {
          phone
          email
          address
        }
      }
    }
  }`
