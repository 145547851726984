export function ShowUSP() {
    var element = document.getElementById("member-table");
    element.classList.add("hidden");

    var element2 = document.getElementById("table-btn");
    element2.classList.remove("selected");

    var element3 = document.getElementById("member-usp");
    element3.classList.remove("hidden");

    var element4 = document.getElementById("usp-btn");
    element4.classList.add("selected");
  }

export function ShowTable() {
var element = document.getElementById("member-usp");
element.classList.add("hidden");

var element2 = document.getElementById("usp-btn");
element2.classList.remove("selected");

var element3 = document.getElementById("member-table");
element3.classList.remove("hidden");

var element4 = document.getElementById("table-btn");
element4.classList.add("selected");
}