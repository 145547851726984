import React from 'react'

export default class Index extends React.Component {

    render() {
        return (
            <form className="contact-form" name="Contact" method="POST" data-netlify="true">
                <div>
                    <input name="Name" type="text" placeholder="Name"/>
                    <input name="Phone" type="text" placeholder="Phone"/>
                    <input name="Email" type="email" placeholder="Email*"/>
                    <input name="Company" type="text" placeholder="Company"/>
                </div>
                <div>
                    <textarea placeholder="Message"/>
                </div>
                <button type="submit" className="submit">Submit</button>
            </form>
        )
    }
}
