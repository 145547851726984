import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({gridItems}) => (
    <div className="row justify-content-center">
        
        {gridItems.usp.map(item => (
                <div className="col-lg-4 col-sm-6 inner-flex-boxes">
                    <div className="item">
                        <span className="icon feature_box_col_one">
                            <PreviewCompatibleImage imageInfo={item.icon}/>
                        </span>
                        <h6 className="font-weight-bold">{item.title}</h6>
                        <p>{item.description}</p>
                    </div>
                </div>
            ))}
    </div>
)
FeatureGrid.propTypes = {
    gridItems: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.markdown,
        icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }))
}

export default FeatureGrid