import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({gridItems}) => (
    <div className="row justify-content-center">
        <div className="section-head col-sm-12">
            <h1 className="font-weight-bold">{gridItems.title}</h1>
            <p>{gridItems.subtitle}</p>
            <h1>{gridItems.text}</h1>
        </div>
        {gridItems
            .icons
            .map(item => (
                <div className="col-lg-4 col-sm-6 inner-flex-boxes">
                    <div className="item">
                        <span className="icon feature_box_col_one">
                            <PreviewCompatibleImage imageInfo={item.image}/>
                        </span>
                        <h6 className="font-weight-bold">{item.title}</h6>
                    </div>
                </div>
            ))}
    </div>
)
FeatureGrid.propTypes = {
    gridItems: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        subtitle: PropTypes.string,
        text: PropTypes.string,
        icons: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.image])
    }))
}

export default FeatureGrid